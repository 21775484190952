import { render, staticRenderFns } from "./QlifeLinkModalButton.vue?vue&type=template&id=f72d451a&scoped=true&"
import script from "./QlifeLinkModalButton.vue?vue&type=script&lang=js&"
export * from "./QlifeLinkModalButton.vue?vue&type=script&lang=js&"
import style0 from "./QlifeLinkModalButton.vue?vue&type=style&index=0&id=f72d451a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f72d451a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
installComponents(component, {VSnackbar})
