<template>
  <div class="qa-link">
    <a
      @click="sendGa('click', 'adhd', 'all-complete-and-ask-qa')"
      :href="askQAUrl"
    >
      <common-button color="accent">
        <div class="display-flex">
          <div class="img">
            <img src="@/assets/adhd/resultB-qa.png" />
          </div>
          <div class="text">
            <div class="description">
              まずはネットで医師に相談
            </div>
            <div class="annotation">
              ※セルフチェックの結果は自動で送信
            </div>
          </div>
        </div>
      </common-button>
    </a>
  </div>
</template>

<script>
import CommonButton from "@/components/adhd/CommonButton";
import { urlMixin } from "@/mixin/adhd";
export default {
  name: "AskQaLinkButton",
  mixins: [urlMixin],
  components: { CommonButton }
};
</script>

<style scoped>
.qa-link {
  margin: 4px 0px 16px 0px;
}

.qa-link button .display-flex .img {
  width: 66px;
}

.qa-link button .display-flex .text {
  flex: 1;
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.qa-link button .display-flex .text .description {
  font-size: 17px;
  font-weight: 700;
  text-align: left;
  color: #324646;
}
.qa-link button .display-flex .text .annotation {
  font-size: 12px;
  text-align: left;
  color: #78828c;
}
</style>
