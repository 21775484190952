<template>
  <div class="page-root">
    <page-title color="blue">
      <template v-slot:right>結果</template>
    </page-title>

    <div class="consultation">
      <doctor-flex-box>
        ADHDについて、<br />医師に相談してみませんか？
      </doctor-flex-box>

      <div class="description">
        <p class="text">
          セルフチェックの結果は、ADHDの特性があるかを見るものであり、診断結果ではありません。
          <br />
          <br />
          ADHDの方はその特性により、生きづらさを感じている方もいらっしゃるかもしれません。医師や心理士は、その解消方法を一緒に考えていきます。ADHDかもしれないと思い、生きづらさを感じられているなら、
          <span>一人で抱え込まずに医師に相談してみませんか？</span>
        </p>
      </div>

      <adhd-action-box />
    </div>

    <hr />

    <div class="ask-qa">
      <div class="qestion-title">
        ネットで医師に相談とは？
      </div>
      <div class="img">
        <img src="@/assets/adhd/resultB-process.png" />
      </div>
    </div>

    <div class="ask-qa-example">
      <div class="title">
        実際のADHDについての相談例
      </div>
      <div v-for="(qa, idx) in qas" :key="idx">
        <div class="display-flex flex-align-center">
          <div class="user-img">
            <img src="@/assets/adhd/resultB-icon-user.png" />
          </div>
          <div class="user-age">
            {{ qa.patientAge }}才 / {{ qa.patientSex }}
          </div>
        </div>
        <div class="qestion-example">
          {{ qa.qestionText }}
        </div>
        <div class="display-flex flex-align-center flex-reverse">
          <div class="doctor-img">
            <img src="@/assets/adhd/resultB-icon-doctor.png" />
          </div>
          <div class="doctor">
            {{ qa.doctorDepartment
            }}<span v-if="1 < qa.answerCount"
              >(他{{ qa.answerCount }}名の医師も回答)</span
            >
          </div>
        </div>
        <div class="doctor-answer-example">
          {{ qa.answerText }}
        </div>
        <hr v-if="idx + 1 !== qas.length" />
      </div>

      <div class="qa-link">
        <ask-qa-link-button />
      </div>
    </div>

    <hr />

    <div class="worry">
      <div class="title">
        医師に相談するべきかお悩み中の方へ
      </div>
      <div class="description">
        急に医師へ相談するのはハードルが高い…という方向けに、様々なADHDコンテンツをご用意しています。
      </div>

      <div class="may-become">
        <may-adhd-box />
      </div>
    </div>

    <div class="counseling">
      <div class="title">
        ADHD相談室
      </div>
      <div class="description">
        治療の期間・病院の探し方…など、ADHDの治療についてお悩みに現役医師が回答します。
      </div>
      <div class="img">
        <img src="@/assets/adhd/consultation-room-icon.png" width="159px" height="147px;" />
      </div>
      <div class="link-button">
        <a href="https://www.askdoctors.jp/articles/201797" target="_blank">
          <common-button color="green">
            <div class="display-flex flex-justify-center flex-align-center">
              <div class="green">
                ADHD相談室を見る
              </div>
              <div class="green">
                <v-icon color="#28C8BE">chevron_right</v-icon>
              </div>
            </div>
          </common-button>
        </a>
      </div>
    </div>

    <adhd-experience-box />

    <div class="adhd-action">
      <adhd-action-box />

      <adhd-special-page-link-button />
    </div>

    <hr />

    <div class="top-link">
      <div class="button-block">
        <link-button link="AdhdAnswerResult" color="green" :is-blank="true">
          自分の回答結果を確認する
        </link-button>
      </div>

      <div class="button-block">
        <link-button link="AdhdFirstScreening">
          セルフチェックを再度行う
        </link-button>
      </div>
    </div>
  </div>
</template>

<script>
import LinkButton from "@/components/adhd/LinkButton";
import { askQA } from "@/data/adhd";
import PageTitle from "@/components/adhd/PageTitle.vue";
import DoctorFlexBox from "@/components/adhd/DoctorFlexBox";
import MayAdhdBox from "@/components/adhd/MayAdhdBox";
import AdhdExperienceBox from "@/components/adhd/AdhdExperienceBox";
import AdhdActionBox from "@/components/adhd/AdhdActionBox";
import AdhdSpecialPageLinkButton from "@/components/adhd/AdhdSpecialPageLinkButton";
import AskQaLinkButton from "@/components/adhd/AskQaLinkButton";
import CommonButton from "@/components/adhd/CommonButton.vue";
export default {
  name: "AdhdThirdResult",
  head: {
    title: {
      inner: "かんたんADHDセルフチェック"
    }
  },
  data: function() {
    return {
      qas: askQA()
    };
  },
  components: {
    LinkButton,
    PageTitle,
    DoctorFlexBox,
    MayAdhdBox,
    AdhdExperienceBox,
    AdhdActionBox,
    AdhdSpecialPageLinkButton,
    AskQaLinkButton,
    CommonButton
  }
};
</script>

<style scoped>
@import "../../assets/style/adhd.css";
hr {
  border-top: 8px solid #f0f3f3;
  margin: 0px;
}
.consultation {
  margin: 24px 16px;
}
.consultation .description {
  padding-top: 16px;
}
.consultation .description .text {
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.consultation .description .text span {
  font-weight: 700;
}
.may-become {
  margin-top: 16px;
}
.ask-qa {
  margin: 40px 16px 0px 16px;
}
.ask-qa .qestion-title {
  font-size: 20px;
  font-weight: 700;
}
.ask-qa .img {
  margin: 24px 0px;
}
.ask-qa-example {
  margin: 16px 16px 40px 16px;
}
.ask-qa-example .title {
  font-size: 20px;
  font-weight: 700;
}
.ask-qa-example .display-flex .user-img {
  width: 42px;
}
.ask-qa-example .display-flex .user-age {
  margin-left: 8px;
}

.ask-qa-example .qestion-example {
  position: relative;
  margin: 16px 0px;
  padding: 20px 16px;
  background-color: #f8f8f8;
  white-space: pre-line;
  border-radius: 4px;
  text-align: left;
  line-height: 24px;
}
.ask-qa-example .display-flex .doctor-img {
  width: 42px;
}

.ask-qa-example .display-flex .doctor {
  margin-right: 8px;
}

.ask-qa-example .qestion-example:before {
  position: relative;
  content: "";
  position: absolute;
  top: -26px;
  left: 17px;
  margin-left: -15px;
  border: 12px solid transparent;
  border-bottom: 15px solid #f8f8f8;
}

.ask-qa-example .doctor-answer-example {
  position: relative;
  margin: 16px 0px;
  padding: 20px 20px;
  background-color: #f0f8fa;
  white-space: pre-line;
  border-radius: 4px;
  color: #324646;
  text-align: left;
  line-height: 24px;
}

.ask-qa-example .doctor-answer-example:before {
  content: "";
  position: absolute;
  top: -26px;
  left: calc(100% - 17px);
  margin-left: -15px;
  border: 12px solid transparent;
  border-bottom: 15px solid #f0f8fa;
}
.ask-qa-example .qa-link {
  margin-top: 26px;
}
.ask-qa-example hr {
  margin: 16px 0px;
  border-top: 1px solid #f0f3f3;
}
.top-link {
  margin: 40px 16px 16px 16px;
}
.top-link .button-block {
  margin: 16px 0px;
}
.worry {
  margin: 40px 16px;
}
.worry .title {
  font-size: 20px;
  font-weight: 700;
  margin: 0px;
  text-align: left;
}
.worry .description {
  margin-top: 16px;
  font-size: 15px;
  text-align: left;
}
.counseling {
  margin: 16px;
  background-color: #28C8BE;
  border-radius: 16px;
}
.counseling .title {
  font-size: 20px;
  font-weight: 700;
  color: white;
  margin: 0;
  padding-top: 24px;
}
.counseling .description {
  font-size: 14px;
  color: white;
  padding: 8px 24px;
}
.counseling .img {
  margin-top: 16px;
  height: 147px;
}
.counseling .link-button {
  padding: 0px 24px 32px 24px;
}
.adhd-action {
  margin: 16px 16px 40px 16px;
}
</style>
