<template>
  <div class="qlife-modal">
    <common-button @click="openModal" color="green">
      <div class="display-flex">
        <div class="img">
          <img src="@/assets/adhd/resultB-hospital.png" />
        </div>
        <div class="text">
          <div class="description">
            専門の病院・医療機関で相談
          </div>
          <div class="annotation">
            ※外部サイトに遷移します
          </div>
        </div>
      </div>
    </common-button>

    <common-modal :is-modal-active="isShowResultModal" @close="closeModal">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <div class="title">
              セルフ問診結果の保存
            </div>
            <div class="description">
              回答情報は、医師にとって役立つ情報となりますので、保存して受診時に医療機関へお見せください。
            </div>
            <div class="copy">
              <div class="description">
                回答結果ページは、ブックマークや印刷などで保管してください。
              </div>
              <div class="copy-button">
                <common-button
                  @click="onCopy"
                  color="blue"
                  backgroundColor="white"
                >
                  回答結果のURLをコピー
                </common-button>
              </div>
            </div>
            <div>
              <a
                @click="
                  sendGa('click', 'adhd', 'all-complete-and-search-hospital')
                "
                href="https://www.qlifeweb.jp/adultdd/?utm_source=askdoctors&utm_medium=referral&utm_campaign=adultddarticles&utm_content=selfcheck"
              >
                <common-button>
                  病院検索に進む（外部サイト）
                </common-button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <v-snackbar v-model="isShowToast" :timeout="2000" :centered="true">
        <div class="snack-bar">
          クリップボードにURLをコピーしました
        </div>
      </v-snackbar>
    </common-modal>
  </div>
</template>

<script>
import CommonButton from "@/components/adhd/CommonButton";
import CommonModal from "@/components/common/CommonModal";
import { urlMixin } from "@/mixin/adhd";
export default {
  name: "AskQaLinkButton",
  components: { CommonButton, CommonModal },
  mixins: [urlMixin],
  data() {
    return {
      isShowResultModal: false,
      isShowToast: false
    };
  },
  methods: {
    onCopy() {
      navigator.clipboard
        .writeText(this.answerResultUrl)
        .then(() => (this.isShowToast = true));
    },
    openModal() {
      this.isShowResultModal = true;
    },
    closeModal() {
      this.isShowResultModal = false;
    }
  }
};
</script>

<style scoped>
.qlife-modal {
  margin: 4px 0px 16px 0px;
}

.qlife-modal button .display-flex .img {
  width: 66px;
}

.qlife-modal button .display-flex .text {
  flex: 1;
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.qlife-modal button .display-flex .text .description {
  font-size: 17px;
  font-weight: 700;
  text-align: left;
  color: #324646;
}
.qlife-modal button .display-flex .text .annotation {
  font-size: 12px;
  text-align: left;
  color: #78828c;
}
.card-content .title {
  margin: 0px 24px;
  font-size: 18px;
  font-weight: 700;
}
.card-content .description {
  margin-top: 24px;
  font-size: 15px;
  text-align: left;
}
.card-content .copy {
  margin: 20px 0px;
  background-color: #f0f8fa;
}
.card-content .copy .description {
  padding: 20px 16px;
  font-size: 13px;
  text-align: left;
}
.card-content .copy .copy-button {
  padding: 0px 16px 20px 16px;
}
</style>
