<template>
  <div class="action">
    <div class="title">
      病院の受診をご希望の方
    </div>

    <qlife-link-modal-button />

    <div class="title block">
      手軽に相談・受診するべきか聞きたい方
    </div>

    <ask-qa-link-button />
  </div>
</template>

<script>
import AskQaLinkButton from "@/components/adhd/AskQaLinkButton";
import QlifeLinkModalButton from "@/components/adhd/QlifeLinkModalButton";
export default {
  name: "AdhdActionBox",
  components: { AskQaLinkButton, QlifeLinkModalButton }
};
</script>

<style scoped>
.action {
  margin: 32px 0px;
}
.action .title {
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
}

.action .block {
  margin-top: 24px;
}
</style>
