<template>
  <b-modal v-model="isActive" :width="width" scroll="keep" @close="onClose">
    <slot></slot>
  </b-modal>
</template>

<script>
export default {
  name: "CommonModal",
  data() {
    return {
      isActive: false
    };
  },
  props: {
    width: {
      type: Number,
      required: false,
      default: 384
    },
    isModalActive: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    onClose() {
      this.$emit("close");
    }
  },
  watch: {
    // v-modelに反映させるにはwatch経由で変更する以外上手くいかなかったので、watchして変更させています
    isModalActive: function(newValue) {
      this.isActive = newValue;
    }
  }
};
</script>
